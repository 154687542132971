<template>
	<div class="Main width_1200 margin_auto">
		<div class="dexBox_week">
			<img src="https://zy.metaera.media/assets/images/dex/984.png" />
		</div>
		<div class="Main width_1200 margin_auto flex justify_content_sb">
			<div class="MainList">
				<div
					class="MainItem tophit"
					:ref="'id' + item.id"
					v-for="(item, index) in SpecialDetails.subordinate"
					:key="index"
				>
					<div class="MainItemHead flex align_items_c">
						<div>{{ item.name }}</div>
					</div>
					<div class="jxlist">
						<div
							class="week_pc align_items_c pointer"
							v-for="(items, indexs) in item.data"
							:key="indexs"
							@click="
								$router.push({
									path: items.type == 2 ? '/kuaixun_wz' : '/gw_detail',
									query: { id: items.id },
								})
							"
						>
							<wzitem :item="items"></wzitem>
						</div>
					</div>
				</div>
			</div>
			<div class="MainRight">
				<div style="margin-top: 22px"></div>
				<resou></resou>
				<!-- 主编推荐 -->
				<event type="recommend" title="主编推荐" style="margin-top: 30px">
				</event>
				<download></download>
			</div>
		</div>
	</div>
</template>

<script>
import { getSpecialDetails } from "@/api/home";
import wzitem from "./../../components/pc/wzitem.vue";
import download from "./../../components/pc/download.vue";
export default {
	name: "",
	components: { wzitem, download },
	data() {
		return {
			active: 0,

			info: {},
			SpecialDetails: {},
		};
	},
	created() {
		this.getSpecialDetails();
	},
	methods: {
		getSpecialDetails() {
			getSpecialDetails({ id: 3 })
				.then((res) => {
					if (res.code == 200) {
						this.SpecialDetails = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		md(item, index) {
			this.active = index;
			this.$el
				.querySelector(".id" + item.id)
				.scrollIntoView({ block: "start", behavior: "smooth" });
		},
	},
};
</script>

<style scoped>
.MainRight {
	display: block;
}
/* Main */
.Main {
	padding-top: 10px;
}
.dexBox_week {
	width: 100%;
	height: 120px;
}
.dexBox_week img {
	width: 100%;
	height: 120px;
	object-fit: cover;
}
.MainList {
	margin-left: 0px;
	width: 600px;
	padding-bottom: 20px;
	margin-top: 10px;
}

.MainItem {
	width: 100%;
	opacity: 1;
	margin-top: 20px;
}

.MainItem:nth-child(1) {
	margin-top: 0;
}

.MainItemHead {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #efefef;
	border-radius: 20px 20px 0 0;
	position: relative;
}

.MainItemHead div {
	position: relative;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.wzitem {
	width: 94%;
	margin: 0 auto;
	height: 142px;
	border-bottom: 1px solid #efefef;
}

.wzitem img {
	width: 160px;
	height: 100px;
	border-radius: 5px;
}

.wzitemL {
	width: 70%;
	margin-left: 21px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.wzitemL div:nth-child(1) {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.wzitemL div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a7;
}
.week_pc {
	display: contents;
}

.steps {
	width: 100px;
	margin-left: 10px;
}

.stepsTime {
	font-size: 12px;
	font-family: DINPro-Regular, DINPro;
	font-weight: 400;
	color: #000000;
}

.stepsVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000 !important;
	margin-top: 5px;
}

.js-box {
	margin-top: 0;
	position: fixed;
}
.jxlist {
	background-color: #fff;
	border-radius: 20px;
}
@media screen and (max-width: 950px) {
	.Main {
		display: contents;
	}

	.MainRight {
		display: none;
	}
	.Main_l {
		width: 100%;
		height: calc(100vw * 568 / 370);
	}
	.MainList {
		width: 100%;
		margin-top: 20px;
		margin-left: 0px;
	}

	.MainItem {
		width: 90%;
		margin-left: 5%;
		/* background: #ffffff; */
		/* border-radius: 18px 18px 18px 18px; */
		opacity: 1;
		margin-top: 20px;
	}
}
</style>
